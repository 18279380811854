import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b5a5fba4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "screen"
}
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DealProductInfo = _resolveComponent("DealProductInfo")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_DealClosed = _resolveComponent("DealClosed")!

  return (_ctx.deal && !_ctx.isDealClosed)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DealProductInfo, { deal: _ctx.deal }, null, 8, ["deal"]),
          _createVNode(_component_Footer)
        ]),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentBottom), {
          deal: _ctx.deal,
          selectedOptions: _ctx.selectedOptions,
          orderId: _ctx.orderId,
          onSwitchToOptions: _ctx.switchToOptions,
          onSwitchToBasic: _ctx.switchToBasic,
          onSwitchToOrder: _ctx.switchToOrder,
          onSwitchToSuccess: _ctx.switchToSuccess
        }, null, 40, ["deal", "selectedOptions", "orderId", "onSwitchToOptions", "onSwitchToBasic", "onSwitchToOrder", "onSwitchToSuccess"]))
      ]))
    : (_ctx.deal)
      ? (_openBlock(), _createBlock(_component_DealClosed, { key: 1 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_3))
}